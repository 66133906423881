import React from "react";

import logo from "../images/header/Logo.svg";
import logoQ from "../images/logo/sign-logo.gif";
import { Link, navigate } from "gatsby";
import "../styles/404.scss";

const NotFoundPage = () => {
  const browser = typeof window !== "undefined" && window;
  return (
    <>
      {browser && (
        <div className="p404">
          <div className="p404__cont">
            <div className="p404__cont__firstCol">
              <Link
                className="p404__cont_a"
                to="/app/dashboard"
                target="_blank">
                <img className="p404__cont__firstCol_logo" src={logo}></img>
              </Link>
            </div>
            <div className="p404__cont_p404">
              <span className="p404__cont_p404_first4">4</span>
              <img className="p404__cont_p404_img" src={logoQ} />
              <span className="p404__cont_p404_second4">4</span>
            </div>
            <div className="p404__cont_bottomCnt">
              <p className="p404__cont_bottomCnt_text">Something's missing.</p>
              <p
                className="t3 p404__cont_bottomCnt_link"
                onClick={() => navigate("/")}>
                Go Ask Dashboard
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NotFoundPage;
